import React from 'react'
import SEO from "src/components/seo"
import Forwarder from 'src/components/Forwarder'

const Shortener = ({slug}) => {
  return (
    <>
      <SEO title='Encurtador de links VilaApps'/>
      <Forwarder 
        slug={slug}
      />
    </>
  )
}

export default Shortener