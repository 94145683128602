import React, {useEffect} from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { gtag } from 'src/services/analytics'

const Forwarder = ({slug}) => {
  const { 
    allStrapiUrLsEncurtadas: {nodes: urls}
  } = useStaticQuery(
      graphql`
        query {
          allStrapiUrLsEncurtadas {
            nodes {
              url
              slug
            }
          }
        }
    `
  )

  useEffect(() => {
    forward()
  }, [slug])

  const forward = () => {
    const url = getUrl()
    
    gtag('forwaded', {slug, valid: url !== '/'})
    window.open(url, '_self')
  }

  const getUrl = () => {
    const isSlug = (value) => {
      return value.slug === slug;
    }
    
    const shortenerObject = urls.filter(isSlug);

    return shortenerObject.length > 0 ? shortenerObject[0].url : '/'
  }

  return (<></>)
}

export default Forwarder